import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';
import Spinner from 'components/@vuexy/spinner/Fallback-spinner';
import { store } from 'redux/storeConfig/store';
import { Layout } from 'utility/context/Layout';
import 'index.scss';

const LazyApp = lazy(() => import('./App'));

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
